import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './ARPromo.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';
import { Button } from '../ui/button/button';
import CarouselVideoOverlayButton from '../Carousel/CarouselVideoOverlayButton';

export type Props = {
  id: string;
  backgroundImage: types.ImageFields;
  headerLogo?: types.ImageFields;
  innerText?: types.TextFields;
  innerMobileText?: types.TextFields;
  offset: number;
  mobileOffset: number;
  mobileCTAButton: types.ButtonFields;
};

export const ARPromo = ({
  id,
  backgroundImage,
  headerLogo,
  innerText,
  innerMobileText,
  offset,
  mobileOffset,
  mobileCTAButton,
}: Props) => {
  const backgroundImageRef = React.useRef<HTMLImageElement>(null);

  const [view, setView] = React.useState<types.Size>(types.Sizes.sm);
  const [backgroundImageHeight, setBackgroundImageHeight] = React.useState(0);
  const [videoOverlayOpen, setVideoOverlayOpen] = React.useState(false);

  const currentOffset = React.useMemo(
    () => (view === types.Sizes.lg ? offset : mobileOffset),
    [view, offset, mobileOffset]
  );
  const currentInnerText = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? innerText?.text ?? ''
        : innerMobileText?.text ?? '',
    [view, innerText, innerMobileText]
  );

  const handleViewMode = React.useCallback(() => {
    if (!window.innerWidth) return;

    setBackgroundImageHeight(backgroundImageRef.current?.offsetHeight || 0);

    setView(() => {
      return window.innerWidth >= types.Breakpoints.md
        ? types.Sizes.lg
        : types.Sizes.sm;
    });
  }, []);

  React.useEffect(() => {
    if (!window) return;

    handleViewMode();
    window.addEventListener('resize', handleViewMode);
    return () => window.removeEventListener('resize', handleViewMode);
  }, [handleViewMode]);

  return (
    <section id={id} className={clsx(scss.wrapper)}>
      <img
        ref={backgroundImageRef}
        className={clsx(scss.backgroundImage)}
        src={getStrapiImageSource(
          backgroundImage.src[view] ?? backgroundImage.src.lg
        )}
        alt={backgroundImage.alt}
        onLoad={() =>
          setBackgroundImageHeight(
            backgroundImageRef.current?.offsetHeight || 0
          )
        }
      />

      <a
        href="https://xr.maxresources.com/the-white-lotus-sweeps/form/"
        target="_blank"
        className={scss.desktopOnly}
        style={{
          position: 'absolute',
          bottom: 0,
          right: '45%',
          top: '82%',
          left: 0,
          width: 'auto',
          height: 'auto',
          opacity: '0',
          // background: 'rgb(255 255 255 / 0.5)',
          zIndex: '1000',
        }}
        rel="noreferrer">
        link
      </a>

      <div
        style={{
          minHeight: `${backgroundImageHeight}px`,
          paddingBottom: `${backgroundImageHeight * (currentOffset / 100)}px`,
        }}
        className={clsx(scss.inner)}>
        {headerLogo && headerLogo.src[view] && (
          <img
            className={clsx(scss.headerLogoImage)}
            src={getStrapiImageSource(
              headerLogo.src[view] ?? headerLogo.src.lg
            )}
            alt={headerLogo.alt}
          />
        )}

        {currentInnerText && (
          <p
            className={clsx(scss.innerText)}
            dangerouslySetInnerHTML={{ __html: currentInnerText }}
          />
        )}

        {/* {!!mobileCTAButton?.text.length && (
          <Button
            className={clsx(scss.backgroundButton)}
            tag="a"
            outline={mobileCTAButton?.outline}
            label={mobileCTAButton?.text}
            href={mobileCTAButton?.link}
            type={mobileCTAButton?.type}
            icon={
              mobileCTAButton?.type === 'overlay'
                ? ['far', 'play-circle']
                : undefined
            }
            mode={mobileCTAButton?.mode}
            style={mobileCTAButton?.style}
          />
        )} */}

        {mobileCTAButton == null || !mobileCTAButton.text.length ? (
          <></>
        ) : mobileCTAButton.type === 'overlay' ? (
          <CarouselVideoOverlayButton
            buttonTitle={mobileCTAButton.text}
            videoTitle={mobileCTAButton.overlayTitle ?? ''}
            videoURL={mobileCTAButton.link}
            buttonStyle={mobileCTAButton.style}
            videoOverlayOpen={videoOverlayOpen}
            setVideoOverlayOpen={setVideoOverlayOpen}
            className={clsx(scss.backgroundButton)}
            outline={mobileCTAButton.outline}
            mode={mobileCTAButton.mode}
          />
        ) : (
          <Button
            tag="a"
            className={clsx(scss.backgroundButton)}
            href={mobileCTAButton.link}
            label={mobileCTAButton.text}
            type={mobileCTAButton.type}
            style={mobileCTAButton.style}
            outline={mobileCTAButton.outline}
            mode={mobileCTAButton.mode}
          />
        )}

        <Button
          className={clsx(scss.backgroundButton, scss.mobileOnly)}
          tag="a"
          outline={mobileCTAButton?.outline}
          label={'Enter Sweepstakes'}
          href="https://xr.maxresources.com/the-white-lotus-sweeps"
          type={'external'}
          mode={mobileCTAButton?.mode}
          style={mobileCTAButton?.style}
        />
      </div>
    </section>
  );
};
